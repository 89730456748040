import React, { Suspense } from 'react';
import './styles/index.css';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { AuthProvider, useAuth } from './AuthContext';

const Login = React.lazy(() => import('./views/Login'));

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));

const Loader = function () {
  return (
    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
      <span
        className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
        style={{ top: '50%' }}
      >
        Loading...
      </span>
    </div>
  );
};

const AppLoader = function () {
  const { isPending, isError } = useAuth();
  if (isPending) {
    return <Loader />;
  }
  return isError ? <Login /> : <AuthenticatedApp />;
};

function App() {
  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <Suspense fallback={<Loader />}>
          <AppLoader />
        </Suspense>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;
