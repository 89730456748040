import { ApolloClient, InMemoryCache } from '@apollo/client';
import { buildApiUrl } from '../utils/api';
import { TypedTypePolicies } from './apollo-helpers';

const typePolicies: TypedTypePolicies = {
  Agent: {
    // This entire definition is typed, based on available types and fields
    fields: {
      lastHeartbeat: {
        read(existing) {
          return new Date(existing);
        },
      },
    },
  },
};
const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies,
  }),
  uri: buildApiUrl('/api/graphql'),
  credentials: 'include',
});

export { client };
