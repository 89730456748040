import React, { useState, createContext, useContext, useEffect } from 'react';
import {
  UserSessionQuery,
  UserSessionDocument,
  SessionFieldsFragment,
} from './graphql/types';
import { client } from './graphql/client';
import { buildApiUrl, subdomain } from './utils/api';

interface AuthContextState {
  session?: SessionFieldsFragment;
  status: string;
  errors?: ReadonlyArray<string>;
  login: (email: string, password: string) => void;
  logout: () => void;
  refresh: () => void;
}

const defaultSignin = async (): Promise<boolean> => {
  return false;
};
const AuthContext = createContext<AuthContextState>({
  login: defaultSignin,
  status: 'pending',
  errors: [],
  logout: defaultSignin,
  refresh: () => {},
});

const getUser = async (): Promise<SessionFieldsFragment> => {
  try {
    const { data } = await client.query<UserSessionQuery>({
      query: UserSessionDocument,
    });

    if (data && data.session) {
      // @ts-ignore
      window.mixpanel.identify(data.session.user.email);
      return data.session;
    }
    throw new Error('no data');
  } catch (e) {
    console.log(e);
    throw new Error('error logging');
  }
};

interface authState {
  session?: SessionFieldsFragment;
  status: string;
  errors?: ReadonlyArray<string>;
}
const signIn = async (email: string, password: string): Promise<boolean> => {
  try {
    const result = await fetch(buildApiUrl('/api/auth/login'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ email, password, alias: subdomain() }),
    });
    if (result.ok) {
      await getUser();
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

interface AuthProviderProps {}
const AuthProvider: React.FC<AuthProviderProps> = function AuthProvider(props) {
  const [state, setState] = useState<authState>({
    status: 'pending',
  });

  const login = async (user: string, password: string) => {
    const success = await signIn(user, password);
    if (!success) {
      setState({ status: 'error', errors: ['Invalid credentials'] });
      return;
    }
    getUser().then(
      (session) => {
        setState({ session, status: 'success' });
      },
      (errors) => {
        setState({ status: 'error', errors });
      }
    );
  };
  const refresh = async () => {
    getUser().then(
      (session) => {
        setState({ session, status: 'success' });
      },
      (errors) => {
        setState({ status: 'error', errors });
      }
    );
  };
  const logout = async () => {
    await fetch(buildApiUrl('/api/auth/logout'));
  };

  useEffect(() => {
    getUser().then(
      (session) => {
        setState({ session, status: 'success' });
      },
      (errors) => {
        console.log(errors);
        setState({ status: 'error', errors });
      }
    );
  }, []);
  return (
    <AuthContext.Provider
      value={{
        session: state.session,
        status: state.status,
        login,
        refresh,
        logout,
        errors: state.errors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
const useAuth = () => {
  const state = useContext(AuthContext);
  const isPending = state.status === 'pending';
  const isSuccess = state.status === 'success';
  const isError = state.status === 'error';
  return {
    ...state,
    isPending,
    isSuccess,
    isError,
  };
};

export { useAuth, AuthProvider };
