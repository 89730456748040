export function apiUrl(): string {
  const { REACT_APP_API_URL } = process.env;
  if (!REACT_APP_API_URL) {
    return '';
  }
  return REACT_APP_API_URL;
}
export function subdomain(): string {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'production') {
    const parts = window.location.hostname.split('.');
    return parts.shift() || '';
  }
  const { REACT_APP_DEFAULT_CLIENT } = process.env;
  return REACT_APP_DEFAULT_CLIENT || 'elemecanic';
}
export function buildApiUrl(path: string): string {
  return `${apiUrl()}${path}`;
}
